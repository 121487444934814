import styled from "@emotion/styled";
import { ReactNode } from "react";

interface IPrimary {
  background?: string;
  color?: string;
  size?: string;
  weight?: string;
  radius?: string;
  padding?: string;
}

export const PrimaryButton = styled.button<IPrimary>`
  display: inline-block;
  cursor: pointer;

  color: ${(props) => props.color};
  background-color: ${(props) => props.background};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};

  border-radius: ${(props) => props.radius};
  padding: ${(props) => props.padding};

  border: none;

  @media (max-width: 480px) {
    font-size: 1.3rem;
  }

  &:disabled {
    transition: background-color 0.2s linear;
    
    background-color: #b4b4b4;
    cursor: not-allowed;
  }
`;

export const PrimaryButtonStyled = styled(PrimaryButton)<{
  show?: boolean;
  background?: string;
  color?: string;
}>`
  background: ${(props) => (props.background ? props.background : "#02447f")};
  color: ${(props) => (props.color ? props.color : "#fff")};
  font-size: 1.8rem;
  border-radius: 10px;
  padding: 1rem 3rem 1.2rem 3rem;

  svg {
    transform: ${(props) => (props.show ? "rotate(180deg)" : "rotate(0deg)")};

    transition: transform 0.2s linear;
  }
  transition: background-color 0.2s linear;

  &:disabled {
    transition: background-color 0.2s linear;

    background-color: #b4b4b4;
    cursor: not-allowed;
  }
`;

interface IOutlined {
  background?: string;
  weight?: string;
}

export const OutlinedButton = styled.button<IOutlined>`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 1rem;
  cursor: pointer;

  color: #000;
  background-color: transparent;
  font-size: 1.8rem;
  font-weight: ${(props) => props.weight};

  border-radius: 10px;
  padding: 1rem 3rem 1.2rem 3rem;

  border: 1px solid ${(props) => props.background};

  svg {
    position: static;
    width: 24px;
    height: 24px;
  }

  @media (max-width: 480px) {
    font-size: 1.3rem;
  }
`;

export const ToggleButtonTab = ({ children }: { children: ReactNode }) => {
  return <ToggleTabStyled>{children}</ToggleTabStyled>;
};

const ToggleTabStyled = styled.div`
  width: 100%;
  display: flex;
  border-radius: 10px;
  background: rgba(51, 85, 104, 0.05);
  box-shadow: inset 0 1px 2px #0021340d;
  margin-top: 16px;

  .toggle_button {
    display: block;
    width: 100%;
    height: 40px;
    margin: 4px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    border-radius: 8px;
    background-color: transparent;
    border: none;

    &--active {
      color: #02447f;
      background: #fff;
      box-shadow: 0 3px 8px #0000001a, 0 1px 1px #00000003, 0 3px 1px #00000008;
    }

    cursor: pointer;
  }
`;
