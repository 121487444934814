import styled from "@emotion/styled";

const JuridicalResultBlock = () => {
  return (
    <JuridicalResultBlockStyled className="flex content-between">
        <ul>
            <li><div className="title-block">Банковские реквизиты:</div></li>
            <li><div className="subtitle-block">Р/с: 1092205954430110</div></li>
            <li><div className="subtitle-block">OAO "Оптима Банк"</div></li>
            <li><div className="subtitle-block">БИК: 109022</div></li>
            <li><div className="subtitle-block">SWIFT: ENEJKG22</div></li>
        </ul>

        <ul>
            <li><div className="title-block">Банк корреспондент:</div></li>
            <li><div className="subtitle-block">Межгосударственный банк</div></li>
            <li><div className="subtitle-block">г.Москва, Россия</div></li>
            <li><div className="subtitle-block">SWIFT: INEARUMM</div></li>
            <li><div className="subtitle-block">БИК: 044525362</div></li>
            <li><div className="subtitle-block">ИНН: 9909400746</div></li>
            <li><div className="subtitle-block">КР/счет: 30111810800000000055</div></li>
            <li><div className="subtitle-block">Р/с: 30101810800000000362</div></li>
        </ul>
    </JuridicalResultBlockStyled>
  );
}

const JuridicalResultBlockStyled = styled.div`
    @media (max-width: 480px) {
        display: block;

        ul {
            margin-bottom: 2rem;

            .title-block {
                margin-bottom: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`

export default JuridicalResultBlock;
