import { useLocation, useNavigate } from "react-router-dom";
import { FlagIcons } from "../../atoms/Icons";
import { PrimaryButton } from "../../atoms/UI/Buttons";
import {
  HeaderBurger,
  HeaderLinksDrop,
  HeaderLinksDropClients,
  HeaderLinksDropFranchise,
  HeaderPage,
} from "./HeaderComponent";
import { Dispatch, SetStateAction, useCallback } from "react";
import { PopoverProfile } from "../../Popover/Popover";

interface IProps {
  handleOpenModal?: () => void;
  setActive: Dispatch<SetStateAction<boolean>>;
  active: boolean;
  phone: string;
  setGetLocation: any;
  getlocation: string;
  showPopover: boolean
}

const HeaderLinks = ({
  handleOpenModal,
  active,
  phone,
  setGetLocation,
  getlocation,
  showPopover
}: IProps) => {
  const params = useLocation();

  const checkingIsPathnames = useCallback(
    (active: boolean) => {
      if (
        params.pathname === "/bis/tracking" ||
        params.pathname === "/bis/tariff" ||
        params.pathname === "/ltl" ||
        params.pathname === "/media" ||
        params.pathname === "/rulesacceptance" ||
        params.pathname === "/bis"
      ) {
        return <HeaderLinksDrop active={active} />;
      } else if (
        params.pathname === "/" ||
        params.pathname === "/tracking" ||
        params.pathname === "/calculater" ||
        params.pathname === "/iin" ||
        params.pathname === "/cargo" ||
        params.pathname === "/tariff" ||
        params.pathname === "/services"
      ) {
        return <HeaderLinksDropClients active={active} />;
      } else {
        return <HeaderLinksDropFranchise active={active} />;
      }
    },
    [params.pathname]
  );

  return (
    <header className="header header--shadow">
      <div className="container">
        <div className="header_inner">
          {checkingIsPathnames(active)}
          <HeaderPage
            className="header--p"
            setGetLocation={setGetLocation}
            getlocation={getlocation}
          />
          <div className="header_lang flex items-center gap-2 header-lang--links">
            <div className="flex items-center gap-1_2 pointer">
              <FlagIcons />
              <div>RU</div>
            </div>

            <div style={{position: 'relative'}}>
              <PrimaryButton
                background="#02447F"
                color="#FFF"
                size="1.8rem"
                padding="10px 30px 12px 30px"
                radius="15px"
                onClick={handleOpenModal}
              >
                {phone ? phone : "Войти"}
              </PrimaryButton>
              
              {showPopover && <PopoverProfile showPopover={showPopover}/>}
            </div>

          </div>

          <HeaderBurger className="burger--links" />
        </div>
      </div>
    </header>
  );
};

export default HeaderLinks;
