const PrivateResultBlock = () => {
  return (
    <div>
      <div className="title-block flex gap-1_2">
        <div>Номер карты:</div>{" "}
        <div>
          <span>4177 4901 9093 5777</span>
          <br />
          <span>4177 4901 7100 5061</span>
          <br />
          <span>4177 4901 7007 5263</span>
        </div>
      </div>
      <div className="title-block">
        Телефон номер: <span>+996709121446</span>
      </div>
    </div>
  );
};

export default PrivateResultBlock;
