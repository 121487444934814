import styled from "@emotion/styled";
import { Input } from "../atoms/UI/Inputs";
import { useState } from "react";
import { PrimaryButtonStyled } from "../atoms/UI/Buttons";
import { ArrowDownIcons, PDFIcons } from "../atoms/Icons";
import { Dropdown } from "../atoms/UI/Dropdown";
import { onKeyPress } from "../../utlis/keypress";
import { ToastContainer, toast } from "react-toastify";
import useValidAxiosError from "../../hooks/useValidAxiosError";

interface DropValue {
  item: string;
  name: string;
  id: number;
}

const CareerForm = () => {
  const [value, setValue] = useState<string>("");
  const [fio, setFio] = useState<string>("");
  const [salary, setSalary] = useState<any>(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [dropValue, setDropValue] = useState<DropValue | null>(null);
  const [dropdown, setDropDown] = useState<boolean>(false);
  const { fetchError } = useValidAxiosError();

  const handleShowDropdownValue = (item: string, name: string, id: number) => {
    setDropValue({ item, name, id });
    setDropDown(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    }
  };

  const handleSubmitForm = async () => {
    if (!value || !salary || !selectedFile || !dropValue?.item || !fio) {
      toast.error("Все поля должный заполнены");
    } else {
      const formData = new FormData();
      formData.append("fullName", fio);
      formData.append("phoneNumber", value);
      formData.append("desiredPosition", dropValue?.item);
      formData.append("desiredSalary", salary);
      formData.append("resumeFile", selectedFile);

      try {
        const response = await fetch(
          "http://localhost:3333/resume",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          toast.success("Данные успешно отправленны");
          setFio("");
          setValue("");
          setSalary(0);
          setSelectedFile(null);
          setFileName("");
          setDropValue(null);
        } else {
          const errorResponse = await response.json();
          toast.error(`Ошибка: ${errorResponse.message}`);
        }
      } catch (error: any) {
        fetchError(error);
      }
    }
  };

  return (
    <>
      <CareerFormStyled>
        <div className="career-block">
          <div className="subtitle-block">ФИО</div>
          <Input
            type="text"
            name="name"
            placeholder="Введите ваше имя"
            value={fio}
            onChange={(e) => setFio(e.target.value)}
          />
        </div>

        <div className="career-block">
          <div className="subtitle-block">Номер телефона</div>
          <Input
            type="text"
            name="phone"
            placeholder="+7"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            maxLength={20}
          />
        </div>

        <div className="career-block">
          <div className="subtitle-block">Выберите должность</div>
          <div className={`career-block_dropdown ${dropdown ? "active" : ""}`}>
            <Input
              type="text"
              name="position"
              placeholder="Выберите должность"
              readOnly
              onClick={() => setDropDown(!dropdown)}
              value={dropValue?.item || ""}
            />
            <ArrowDownIcons />
            {dropdown && (
              <Dropdown
                items={["Frontend Developer"]}
                name="Frontend Developer"
                id={1}
                handleAddValueFromDropdown={handleShowDropdownValue}
              />
            )}
          </div>
        </div>

        <div className="career-block">
          <div className="subtitle-block">Желаемая заработная плата</div>
          <Input
            type="text"
            name="salary"
            placeholder="Заработная плата"
            pattern="[0-9]*"
            value={
              salary === 0 || isNaN(salary)
                ? ""
                : salary.toLocaleString("ru-RU")
            }
            onChange={(e) => {
              const value = parseInt(e.target.value.replace(/\D/g, ""));
              setSalary(isNaN(value) ? 0 : value);
            }}
            onKeyPress={onKeyPress}
          />
        </div>
        <DraggerStyled className="kb-file-upload career-block">
          <div className="subtitle-block">Вставьте резюме</div>
          <div className="file-upload-box">
            <input
              type="file"
              id="fileupload"
              className="file-upload-input"
              accept="application/pdf"
              onChange={handleInputChange}
            />
            <span className="subtitle-block">
              Выберите файл или перетащите в эту область
            </span>
          </div>

          {fileName && (
            <div
              className="flex items-center gap-1_2 file_name"
              style={{ marginTop: "2rem"}}
            >
              <div>
                <PDFIcons />
              </div>
              <div>{fileName}</div>
            </div>
          )}
        </DraggerStyled>
        <PrimaryButtonStyled onClick={handleSubmitForm}>
          Отправить резюме
        </PrimaryButtonStyled>
      </CareerFormStyled>
      <ToastContainer />
    </>
  );
};

const CareerFormStyled = styled.div`
  .career-block {
    margin-bottom: 1.6rem;
  }

  .subtitle-block {
    margin-bottom: 1.2rem;
  }

  .career-block_dropdown {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 30px;
      transition: transform 0.2s linear;
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }

    input {
      cursor: pointer;
    }
  }
`;

export const DraggerStyled = styled.div`
  width: 100%;
  max-width: 204px;
  flex: 1;

  .kb-modal-data-title {
    margin-bottom: 10px;
  }

  .kb-data-title h6 {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 600;
  }

  .kb-file-upload {
    margin-bottom: 20px;
  }

  .file-upload-box {
    border: 1px dashed #000018;
    background-color: #efefef;
    border-radius: 10px;
    min-height: 107px;
    position: relative;
    overflow: hidden;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9b9b9b;
  }

  .file-upload-box .file-upload-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  .file-link {
    color: #475f7b;
    text-decoration: underline;
    margin-left: 3px;
  }

  .file-upload-box .file-link:hover {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .file_name  {
      width: 100%;
      overflow: hidden;
    }
  }
`;

export default CareerForm;
