import styled from "@emotion/styled";

export const Block = styled.div<{ width?: string }>`
  width: ${(props) => (props.width ? props.width : "100%")};
  border-radius: 10px;
  padding: 4rem;
  background-color: #ffffff;

  @media (max-width: 480px) {
    padding: 2rem;
  }
`;

export const FormField = styled.div`
  margin-bottom: 1.2rem;
`;

export const TabBarBlock = styled.div<{ payment?: boolean }>`
  display: flex;
  gap: 0.8rem;

  button {
    border-radius: 25px;

    transition: background-color 0.2s linear;

    @media (max-width: 480px) {
      width: 100%;
      padding-inline: 0.2rem;
      font-size: 1.2rem;
      padding-block: 1rem;
    }
  }

  ${(props) =>
    props.payment &&
    `
  button {
    padding: 8px 12px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  }
}
  `}
`;
