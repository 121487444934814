import styled from "@emotion/styled";
import MainLayouts from "../layouts/MainLayouts";
import Calculater from "../components/organisms/Calculater";
import CalculaterCard from "../components/molecules/CalculaterCard";
import useManagerModal from "../hooks/useManagerModal";
import RequestDeliveryModal from "../components/modal/RequestDeliveryModal";
import BusinessSliderContent from "../components/molecules/BusinessSliderContent";
import RequestModal from "../components/modal/RequestModal";
import ChangeModal from "../components/modal/ChangeModal";

const Business = () => {
  const {
    modalInfo,
    modalInfo: modalInfo2,
    modalInfo: modalInfo3,
    setRequestDelivery,
    setRequest,
    clearModal,
    setChangeModal,
  } = useManagerModal();

  const onShowModalRequest = () => {
    setRequestDelivery("Оформить заказ");
  };

  const onShowModalRequestCall = () => {
    setRequest("Оформить заказ");
  };

  const onShowModalRequestChange = () => {
    setChangeModal(
      "Для вызова выездной группы войдите в личный кабинет",
      "Вы можете вызвать группу без регистрации"
    );
  };

  return (
    <>
      {modalInfo && (
        <RequestDeliveryModal
          title={modalInfo.title as string}
          clearModal={clearModal}
        />
      )}

      {modalInfo2 && (
        <RequestModal
          title={modalInfo2.title as string}
          clearModal={clearModal}
        />
      )}

      {modalInfo3 && (
        <ChangeModal
          title={modalInfo3.title as string}
          desc={modalInfo3.desc as string}
          clearModal={clearModal}
        />
      )}
      <MainLayouts title="Бизнес">
        <CalculaterBlock className="calculater">
          <BusinessSliderContent
            onShowModalRequest={onShowModalRequest}
            onShowModalRequestChange={onShowModalRequestChange}
            onShowModalRequestCall={onShowModalRequestCall}
          />

          <Calculater
            title="Рассчитать стоимость доставки"
            subtitle="Юридические отправления рассчитываются в личном кабинете"
            link
          />
        </CalculaterBlock>
        <CalculaterCard />
      </MainLayouts>
    </>
  );
};

const CalculaterBlock = styled.div`
  padding-block: 8rem;
  padding-top: 0;

  @media (max-width: 480px) {
    padding-block: 3rem;
  }

  .calculater_content {
    text-align: center;

    button {
      margin-bottom: 10rem;
      @media (max-width: 480px) {
        margin-bottom: 3rem;
      }
    }
  }

  .calculater_title {
    font-size: 3.8rem;
    font-weight: 600;
    margin-bottom: 3rem;

    @media (max-width: 480px) {
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }

    &--blue {
      color: #02447f;
    }

    &--green {
      color: #94c325;
    }
  }

  .calculater_subtitle {
    font-size: 2.4rem;
    font-weight: 600;
    margin-bottom: 5rem;

    @media (max-width: 480px) {
      font-size: 1.8rem;
      margin-bottom: 3rem;
    }
  }
`;

export default Business;
