import React, { useState, useCallback } from "react";
import { OutlinedButton, PrimaryButtonStyled } from "../atoms/UI/Buttons";
import { InputBorder } from "../atoms/UI/Inputs";
import AppModal from "./AppModal";
import LoginModal from "./LoginModal";
import { instance } from "../../api/instance.axios";
import useManagerModal from "../../hooks/useManagerModal";
import useValidAxiosError from "../../hooks/useValidAxiosError";
import { ToastContainer, toast } from "react-toastify";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useAppDispatch } from "../../hooks/useAppDisptach";
import { actions } from "../../store/slice/profile.slice";
import { Link } from "react-router-dom";
import { TelegramIcons } from "../atoms/Icons";

interface IProps {
  title: string;
  clearModal: () => void;
}

const PhoneModal: React.FC<IProps> = ({ title, clearModal }) => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    code: "",
  });
  const [showVerification, setShowVerification] = useState(false);
  const { fetchError } = useValidAxiosError();
  const { modalInfo, setLoginModal } = useManagerModal();
  const loader = useTypedSelector((state) => state.profile.loading);
  const dispatch = useAppDispatch();

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const { name, email, phone, password, code } = formState;

      if (
        !name ||
        !email ||
        !phone ||
        !password ||
        (showVerification && !code)
      ) {
        toast.error("Все поля должны быть заполнены");
        return;
      }

      try {
        dispatch(actions.setLoading(true));
        const endpoint = showVerification
          ? "/auth/signup-step2"
          : "/auth/signup-step1";
        const payload = { fio: name, email, phone, password, code };

        await instance.post(endpoint, payload).then(() => {
          if (!showVerification) {
            setShowVerification(true);
          } else {
            setLoginModal("Войти");
          }
        });
      } catch (err: any) {
        fetchError(err);
      } finally {
        dispatch(actions.setLoading(false));
      }
    },
    [formState, showVerification, fetchError, dispatch, setLoginModal]
  );

  return (
    <>
      {modalInfo && (
        <LoginModal clearModal={clearModal} title={modalInfo.title as string} />
      )}
      <AppModal name={"phone"} onClose={clearModal}>
        <div className="title-block">{title}</div>

        <form onSubmit={handleSubmit}>
          <InputBorder
            type="text"
            placeholder="Имя, Фамилия"
            name="name"
            value={formState.name}
            onChange={handleChange}
            style={{ borderRadius: "10px", marginBottom: "1.6rem" }}
            border="1px solid #8C8C8C"
          />

          <InputBorder
            type="email"
            placeholder="Email"
            name="email"
            value={formState.email}
            onChange={handleChange}
            style={{ borderRadius: "10px", marginBottom: "1.6rem" }}
            border="1px solid #8C8C8C"
          />

          <InputBorder
            type="text"
            placeholder="+7 () ___ __ __"
            name="phone"
            value={formState.phone}
            onChange={handleChange}
            style={{ borderRadius: "10px", marginBottom: "1.6rem" }}
            border="1px solid #8C8C8C"
            maxLength={20}
          />

          <InputBorder
            type="password"
            placeholder="Придумаете пароль"
            name="password"
            value={formState.password}
            onChange={handleChange}
            style={{ borderRadius: "10px", marginBottom: "1.6rem" }}
            border="1px solid #8C8C8C"
          />

          {showVerification && (
            <InputBorder
              type="text"
              placeholder="Код верификации"
              name="code"
              value={formState.code}
              onChange={handleChange}
              style={{ borderRadius: "10px", marginBottom: "1.6rem" }}
              border="1px solid #8C8C8C"
            />
          )}

          <Link to={"https://t.me/alphacargoverify_bot"} target="_blank">
            <OutlinedButton
              background="#02447F"
              weight="600"
              style={{ width: "100%", marginBottom: "1.6rem" }}
              type="button"
            >
              Подписаться на бота
              <TelegramIcons />
            </OutlinedButton>
          </Link>

          <PrimaryButtonStyled
            style={{ width: "100%" }}
            type="submit"
            disabled={loader}
          >
            Регистрация
          </PrimaryButtonStyled>
        </form>
      </AppModal>

      <ToastContainer />
    </>
  );
};

export default PhoneModal;
