import { IParcelsInformation, IParcelsInvoiceInformation } from "../../types/assets.types";

interface NotificationState {
    percels: IParcelsInformation
    notifications: any[]
    invoice: IParcelsInvoiceInformation
    loading: boolean
    pdf: string
  }

export const initialState: NotificationState = {
    percels: {},
    notifications: [],
    invoice: {},
    loading: false,
    pdf: ""
  };