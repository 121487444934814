import { useEffect } from "react";
import { instance } from "../../../../../api/instance.axios";
import { useParams } from "react-router-dom";
import { getTokenInLocalStorage } from "../../../../../utlis/getTokenLocal";

const NotificationComponent = () => {
  // const params = useParams();

  // useEffect(() => {
  //   if (params.id) {
  //     patchNotificationRead(String(params.id));
  //   }
  // }, [params.id]);

  return (
    <>
      <div className="title">Единый бесплатный номер в Казахстане</div>

      <div className="title-block">25 апреля 2017 г.</div>

      <div className="subtitle-block">
        Уважаемые партнеры и клиенты! Рады вам сообщить, что на территории
        Республики Казахстан заработал единый бесплатный номер 8-800-070-14-05
        Профессиональные консультации двадцать четыре часа в сутки! Спасибо что
        выбрали компанию Альфа Карго
      </div>
    </>
  );
};

export default NotificationComponent;
