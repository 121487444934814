import { FC } from "react";
import { Block } from "../../atoms/UI/Block";
import { PrimaryButtonStyled } from "../../atoms/UI/Buttons";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

interface IProps {
  notPayment: any[] | undefined;
  setPdfInvoice: any
}

const NotPayBlock: FC<IProps> = ({ notPayment, setPdfInvoice }) => {
  if (notPayment?.length === 0) {
    return <div className="title-block" style={{textAlign: 'center'}}>Пусто</div>;
  }

  return (
    <>
      {notPayment?.map((item, index) => (
        <Block
          style={{
            backgroundColor: "#DF5146",
            marginTop: "2rem",
            color: "white",
            cursor: "pointer",
          }}
          key={index + 1}
        >
          <div className="title-block"  onClick={() => setPdfInvoice(item.invoice)}>Номер накладной: <InvoiceSpan>{item.invoice}</InvoiceSpan></div>
          <div className="subtitle-block">Дата: {item.date}</div>
          <div className="subtitle-block">Статус: {item.status}</div>
          <Link to={'/payment'}><PrimaryButtonStyled background="#94c325" style={{marginTop: '1.2rem'}}>Оплатить</PrimaryButtonStyled></Link>
        </Block>
      ))}
    </>
  );
};

const InvoiceSpan = styled.span`
  text-decoration: underline;
  color: white!important; 
`


export default NotPayBlock;
