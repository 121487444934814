import { AxiosError } from "axios";
import { useState } from "react";
import { toast } from 'react-toastify'

const useValidAxiosError = () => {
    const [error, setError] = useState<string>("") 
    const fetchError = (err: Error) => {
        console.log('fetchError called with:', err);
        if (err instanceof AxiosError) {
            const errorMessage = Array.isArray(err.response?.data.message)
                ? err.response?.data.message.join(" ")
                : err.response?.data.message;
            toast.error(errorMessage);
            setError(errorMessage)
        } else if (typeof err === "object" && err !== null && "response" in err) {
            const axiosError = err as { response: { data: { message: string } } };
            toast.error(JSON.stringify(axiosError.response.data.message));
            setError(JSON.stringify(axiosError.response.data.message))
        } else {
            toast.error("Произошла неизвестная ошибка");
        }
    }

    return {
        fetchError,
        error
    }
}

export default useValidAxiosError;
