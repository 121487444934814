import {
  GoogleMap,
  LoadScript,
  Marker,
  DirectionsRenderer,
  StandaloneSearchBox,
} from "@react-google-maps/api";

import { useCallback, useEffect, useRef, useState } from "react";
import { Input } from "../atoms/UI/Inputs";
import { MapFilterSearchIcons } from "../atoms/Icons";
import { ToastContainer, toast } from "react-toastify";

const MapMarker = ({
  searchLatLng,
  offices,
  setOfficeSet,
  officetSet,
}: {
  searchLatLng?: string;
  setCityName?: any;
  offices?: any;
  setOfficeSet?: any;
  officetSet?: any;
}) => {
  const [selectedMarker, setSelectedMarker] = useState<any>(null);

  const [currentPosition, setCurrentPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [directionsClick, setDirectionsClick] = useState<any>(null);
  const [selectedDestination, setSelectedDestination] = useState<any>(null);
  const [searchBox, setSearchBox] = useState<any>(null);
  const [searchPlaces, setSearchPlaces] = useState<any>();
  const [markers, setMarkers] = useState<any[]>([]);
  const [clickMarkers, setClickMarkers] = useState<any>();
  const [_, setDesActive] = useState<boolean>(false);
  const [markActive, setMarkActive] = useState<boolean>(false);
  const mapRef = useRef<google.maps.Map | null>(null);
  const selectedDestinationVariant = markActive
    ? clickMarkers
    : selectedDestination;
  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position);
        const { latitude, longitude } = position.coords;
        setCurrentPosition({ lat: latitude, lng: longitude });
      },
      (error) => console.log(error),
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    );
  }, []);

  useEffect(() => {
    if (currentPosition && selectedDestinationVariant) {
      const directionsService = new window.google.maps.DirectionsService();
      directionsService.route(
        {
          origin: currentPosition,
          destination: selectedDestinationVariant,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          console.log(result);
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirectionsClick(result);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, [currentPosition, selectedDestination, clickMarkers]);

  useEffect(() => {
    const initialMarkers = [
      {
        id: 1,
        lat: 55.7558,
        lng: 37.6176,
        city: { cityname: "Москва" },
        countryname: "Россия",
      },
      {
        id: 2,
        lat: 53.2038,
        lng: 50.1606,
        city: { cityname: "Самара" },
        countryname: "Россия",
      },
      {
        id: 3,
        lat: 55.7943,
        lng: 49.1115,
        city: { cityname: "Казань" },
        countryname: "Россия",
      },
      {
        id: 4,
        lat: 54.7388,
        lng: 55.9721,
        city: { cityname: "Уфа" },
        countryname: "Россия",
      },
      {
        id: 5,
        lat: 51.7666,
        lng: 55.1,
        city: { cityname: "Оренбург" },
        countryname: "Россия",
      },
      {
        id: 6,
        lat: 55.1644,
        lng: 61.4368,
        city: { cityname: "Челябинск" },
        countryname: "Россия",
      },
      {
        id: 7,
        lat: 56.8389,
        lng: 60.6057,
        city: { cityname: "Екатеринбург" },
        countryname: "Россия",
      },
      {
        id: 8,
        lat: 54.9885,
        lng: 73.3242,
        city: { cityname: "Омск" },
        countryname: "Россия",
      },
      {
        id: 9,
        lat: 55.0302,
        lng: 82.9204,
        city: { cityname: "Новосибирск" },
        countryname: "Россия",
      },
      {
        id: 10,
        lat: 56.0086,
        lng: 92.87,
        city: { cityname: "Красноярск" },
        countryname: "Россия",
      },
      {
        id: 11,
        lat: 53.72,
        lng: 91.4424,
        city: { cityname: "Абакан" },
        countryname: "Россия",
      },
      {
        id: 12,
        lat: 52.2978,
        lng: 104.2964,
        city: { cityname: "Иркутск" },
        countryname: "Россия",
      },
      {
        id: 13,
        lat: 42.8746,
        lng: 74.6122,
        city: { cityname: "Бишкек" },
        countryname: "Кыргызстан",
      },
    ];

    const combinedMarkers = [...initialMarkers, ...(offices || [])];
    setMarkers(combinedMarkers);
  }, [offices]);

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: false,
    gestureHandling: "cooperative",
  };

  const onSearchLoad = (ref: any) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    const places = searchBox.getPlaces();
    if (places.length === 0) return;

    if (searchPlaces) {
      setOfficeSet(searchPlaces);
    }

    const place = places[0];
    const location = place.geometry?.location;
    if (location) {
      const newMarkers = markers.map((marker) => {
        const distance =
          window.google.maps.geometry.spherical.computeDistanceBetween(
            location,
            new window.google.maps.LatLng(marker.lat, marker.lng)
          );
        return {
          ...marker,
          highlighted: distance < 50000,
        };
      });

      setMarkers(newMarkers);

      mapRef.current?.setCenter(location);
      mapRef.current?.setZoom(12);
    }
  };

  useEffect(() => {
    if (searchLatLng) {
      const search = markers.find(
        (item) => item.city.cityname === searchLatLng
      );
      if (search) {
        setClickMarkers({ lat: search.lat, lng: search.lng });
        setMarkActive(true);
        setDesActive(false);
      } else {
        toast.error("Такой город не существует");
      }
    }
  }, [searchLatLng]);

  // useEffect(() => {
  //   const geocoder = new window.google.maps.Geocoder();
  //   geocoder.geocode({ location: currentPosition }, (results, status) => {
  //     if (status === "OK") {
  //       if (results && results[0]) {
  //         const addressComponents = results[0].address_components;
  //         const cityComponent = addressComponents.find((component) => {
  //           const translatedTypes = component.types.map((type) => {
  //             const translations: { [key: string]: string } = {
  //               locality: "населённый пункт",
  //               administrative_area_level_1: "административная область",
  //               country: "страна",
  //             };
  //             return translations[type] || type;
  //           });
  //           return translatedTypes.includes("населённый пункт");
  //         });
  //         if (cityComponent) {
  //           const cityName = cityComponent.long_name;
  //           console.log("Название города на английском:", cityName);
  //           setCityName(cityName === "Almaty" ? "Алматы" : cityName);
  //           const russianCityName = cityComponent;
  //           console.log("Название города на русском:", russianCityName);
  //         }
  //       } else {
  //         console.log("Результаты не найдены");
  //       }
  //     } else {
  //       console.log("Ошибка при выполнении обратного геокодирования: ", status);
  //     }
  //   });
  // }, [currentPosition]);

  const handleMarkerClick = useCallback(
    (marker: any, dis: any) => {
      setSelectedMarker(marker);
      setSelectedDestination(dis);
      setMarkActive(false);
      setDesActive(true);

      if (marker.city.cityname) {
        setOfficeSet(marker.city.cityname);
      }

      if (mapRef.current) {
        mapRef.current.setZoom(10);
        mapRef.current.panTo({
          lat: parseFloat(marker.lat),
          lng: parseFloat(marker.lng),
        });
      }
    },
    [officetSet, searchLatLng]
  );

  const getIcon = (highlighted: boolean) => {
    if (!window.google) return undefined;
    return {
      url: require("../../assets/Mark.png"),
      scaledSize: new window.google.maps.Size(30, 30),
      fillColor: highlighted ? "red" : "blue",
    };
  };

  return (
    <>
      <LoadScript
        googleMapsApiKey={"AIzaSyCN0pnM5Br2INjr7Ngp7RnIMzJMsse-ugI"}
        libraries={["places", "geometry"]}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          onLoad={onLoad}
          zoom={10}
          options={mapOptions}
        >
          <StandaloneSearchBox
            onLoad={onSearchLoad}
            onPlacesChanged={onPlacesChanged}
          >
            <div className="map_filter-search">
              <Input
                name="search"
                placeholder="Укажите ближайший адрес"
                style={{ backgroundColor: "#FFF" }}
                onChange={(e) => setSearchPlaces(e.target.value)}
              />
              <MapFilterSearchIcons />
            </div>
          </StandaloneSearchBox>
          {markers.map((marker) => (
            <Marker
              key={marker.id}
              position={{
                lat: parseFloat(marker.lat),
                lng: parseFloat(marker.lng),
              }}
              onClick={() =>
                handleMarkerClick(marker, {
                  lat: parseFloat(marker.lat),
                  lng: parseFloat(marker.lng),
                })
              }
              icon={getIcon(marker.highlighted)}
            />
          ))}

          {directionsClick && (
            <DirectionsRenderer directions={directionsClick} />
          )}
        </GoogleMap>
      </LoadScript>

      <ToastContainer />
    </>
  );
};

export default MapMarker;
