import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Popover, { PopoverClient, PopoverService } from "../../Popover/Popover";
import { ArrowDownIcons } from "../../atoms/Icons";
import { Dispatch, SetStateAction } from "react";
import useManagerModal from "../../../hooks/useManagerModal";
import RequestDeliveryModal from "../../modal/RequestDeliveryModal";
import LoginModal from "../../modal/LoginModal";
import { getTokenInLocalStorage } from "../../../utlis/getTokenLocal";
import ChangeModal from "../../modal/ChangeModal";

interface IProps {
  className?: string;
  setActive?: Dispatch<SetStateAction<boolean>>;
  active?: boolean;
  getlocation?: string;
  setGetLocation?: any;
}

export const HeaderPage = ({ className }: IProps) => {
  const params = useLocation();
  return (
    <nav className={`nav nav--page ${className}`}>
      <Link
        to={"/"}
        className={`nav_links nav_links--page ${
          params.pathname === "/" ? "active" : ""
        } ${className}`}
      >
        Частным клиентам
      </Link>
      <Link
        to={"/bis"}
        className={`nav_links nav_links--page  ${
          params.pathname === "/bis" ? "active" : ""
        } ${className}`}
      >
        Бизнесу
      </Link>
      <Link
        to={"/history"}
        className={`nav_links nav_links--page ${
          params.pathname === "/history" ? "active" : ""
        } ${className}`}
      >
        О нас
      </Link>
      <Link
        to={"/contacts"}
        className={`nav_links nav_links--page ${
          params.pathname === "/contacts" ? "active" : ""
        } ${className}`}
      >
        Контакты
      </Link>
    </nav>
  );
};

export const HeaderLinksDrop = ({ active }: { active: boolean }) => {
  const { modalInfo, clearModal, setRequestDelivery } = useManagerModal();

  const onShowModalRequest = () => {
    setRequestDelivery("Оформить заказ");
  };

  return (
    <>
      {modalInfo && (
        <RequestDeliveryModal
          title={modalInfo.title as string}
          clearModal={clearModal}
        />
      )}
      <nav className={`nav nav--drop ${active ? "active" : ""}`}>
        <div className="nav_links-popover" style={{ position: "relative" }}>
          <Link to={"/bis/tracking"} className="nav_links nav_links--drop">
            Отследить отправки
          </Link>
          <div className="subtitle-block">
            Рассчитайте стоимость отправки своего груза
          </div>
        </div>
        <div className="nav_links-popover" style={{ position: "relative" }}>
          <a className="nav_links nav_links--drop">
            Сервисы
            <ArrowDownIcons />
          </a>
          <div className="subtitle-block">Вызовите выездную группу</div>
          <PopoverClient onShowModalRequest={onShowModalRequest} />
        </div>
        <div className="nav_links-popover" style={{ position: "relative" }}>
          <Link
            style={{ cursor: "pointer" }}
            className="nav_links nav_links--drop"
            to={"/iin"}
          >
            Заключить договор
          </Link>
          <div className="subtitle-block">
            Узнайте цены на посылки в разные города
          </div>
        </div>
        <div className="nav_links-popover" style={{ position: "relative" }}>
          <Link className="nav_links nav_links--drop" to={"/rulesacceptance"}>
            Договоры и инструкции
          </Link>
          <div className="subtitle-block">
            Узнайте цены на посылки в разные города
          </div>
        </div>
      </nav>
    </>
  );
};

export const HeaderLinksDropClients = ({ active }: { active: boolean }) => {
  const {
    modalInfo,
    setRequestDelivery,
    setLoginModal,
    clearModal,
    setChangeModal,
  } = useManagerModal();
  const navigate = useNavigate();

  const onShowModalRequest = () => {
    setRequestDelivery("Оформить заказ");
  };

  const onShowModalRequestChange = () => {
    setChangeModal(
      "Для вызова выездной группы войдите в личный кабинет",
      "Вы можете вызвать группу без регистрации"
    );
  };

  const onShowModalLogin = (link: string) => {
    if (!getTokenInLocalStorage()) {
      setLoginModal("Войти");
    } else {
      navigate(link);
    }
  };

  return (
    <>
      {modalInfo && (
        <RequestDeliveryModal
          title={modalInfo.title as string}
          clearModal={clearModal}
        />
      )}
      {modalInfo && (
        <LoginModal title={modalInfo.title as string} clearModal={clearModal} />
      )}
      {modalInfo && (
        <ChangeModal
          title={modalInfo.title as string}
          desc={modalInfo.desc as string}
          clearModal={clearModal}
        />
      )}
      <nav className={`nav nav--drop ${active ? "active" : ""}`}>
        <div className="nav_links-popover" style={{ position: "relative" }}>
          <a className="nav_links nav_links--drop">
            Отправить или получить
            <ArrowDownIcons />
          </a>
          <div className="subtitle-block">
            Рассчитайте стоимость отправки своего груза
          </div>
          <Popover
            onShowModalRequest={onShowModalRequest}
            onShowModalRequestChange={onShowModalRequestChange}
          />
        </div>
        <div className="nav_links-popover" style={{ position: "relative" }}>
          <Link to={"/tracking"} className="nav_links nav_links--drop">
            Отследить посылку
          </Link>
          <div className="subtitle-block">Вызовите выездную группу</div>
        </div>
        <div className="nav_links-popover" style={{ position: "relative" }}>
          <a className="nav_links nav_links--drop">
            Сервисы и продукты
            <ArrowDownIcons />
          </a>
          <div className="subtitle-block">
            Рассчитайте стоимость отправки своего груза
          </div>
          <PopoverService onShowModalRequest={onShowModalLogin} />
        </div>
        <div className="nav_links-popover" style={{ position: "relative" }}>
          <Link to={"/rulesacceptance"} className="nav_links nav_links--drop">
            Правила приема и доставки
          </Link>
          <div className="subtitle-block">
            Узнайте цены на посылки в разные города
          </div>
        </div>
      </nav>
    </>
  );
};

export const HeaderLinksDropFranchise = ({ active }: { active: boolean }) => {
  return (
    <nav className={`nav nav--drop ${active ? "active" : ""}`}>
      <div className="nav_links-popover" style={{ position: "relative" }}>
        <Link to={"/history"} className="nav_links nav_links--drop">
          История компании
        </Link>
      </div>
      <div className="nav_links-popover" style={{ position: "relative" }}>
        <Link to={"/presscenter"} className="nav_links nav_links--drop">
          Пресс-центр
        </Link>
      </div>
      <div className="nav_links-popover" style={{ position: "relative" }}>
        <Link to={"/guide"} className="nav_links nav_links--drop">
          Руководство
        </Link>
      </div>
      <div className="nav_links-popover" style={{ position: "relative" }}>
        <Link to={"/career"} className="nav_links nav_links--drop">
          Карьера
        </Link>
      </div>
      <div className="nav_links-popover" style={{ position: "relative" }}>
        <Link to={"/franchise"} className="nav_links nav_links--drop">
          Франшиза
        </Link>
      </div>
      <div className="nav_links-popover" style={{ position: "relative" }}>
        <Link to={"/invest"} className="nav_links nav_links--drop">
          Инвесторам
        </Link>
      </div>
    </nav>
  );
};

export const HeaderBurger = ({
  className,
  setActive,
  active,
}: Pick<IProps, "active" | "className" | "setActive">) => {
  return (
    <>
      <button
        className={`burger ${className}`}
        onClick={() => setActive && setActive(!active)}
      >
        <span className="burger_item"></span>
        <span className="burger_item"></span>
        <span className="burger_item"></span>
      </button>
    </>
  );
};
