import { PrimaryButtonStyled } from "../atoms/UI/Buttons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper/modules";
import styled from "@emotion/styled";

const BusinessSliderContent = ({
  onShowModalRequest,
  onShowModalRequestCall,
  onShowModalRequestChange
}: {
  onShowModalRequest: () => void;
  onShowModalRequestCall: () => void;
  onShowModalRequestChange: () => void
}) => {
  return (
    <SwiperStyled>
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={50}
        navigation
        pagination={{ clickable: true }}
        autoHeight
      >
        <SwiperSlide>
          <div className="calculater_content">
            <div className="calculater_title">
              Мы - доставляем{" "}
              <span className="calculater_title--blue">быстро</span>, вы -
              платите <span className="calculater_title--green">мало</span>
            </div>

            <div className="calculater_subtitle">По России и Кыргызстану</div>

            <PrimaryButtonStyled
              color="white"
              background="#94C325"
              onClick={onShowModalRequest}
            >
              Оформить заказ
            </PrimaryButtonStyled>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="calculater_content">
            <div className="calculater_title">
              Отправки
              <span className="calculater_title--green"> каждый день</span>
            </div>

            <div className="calculater_subtitle">
              по всем городам <span style={{ color: "red" }}>России</span>
            </div>

            <PrimaryButtonStyled
              color="white"
              background="#94C325"
              onClick={onShowModalRequestCall}
            >
              Связаться
            </PrimaryButtonStyled>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="calculater_content">
            <div className="calculater_title">
              Выездная группа которая заберет ваш товар из
              <span className="calculater_title--green">
                {" "}
                цеха, магазина и рынка
              </span>
            </div>

            <div className="calculater_subtitle">
              И отправит в <span style={{ color: "red" }}>Россию</span>
            </div>

            <PrimaryButtonStyled
              color="white"
              background="#94C325"
              onClick={onShowModalRequestChange}
            >
              Оставить заявку
            </PrimaryButtonStyled>
          </div>
        </SwiperSlide>
      </Swiper>
    </SwiperStyled>
  );
};

const SwiperStyled = styled.div`
  .swiper-button-next,
  .swiper-button-prev {
    color: #8c8c8c;
  }

  .swiper-pagination {
    bottom: 3rem;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 10px;
    height: 10px;
  }

  .swiper-pagination-bullet {
    background: #8c8c8c;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: #02447f;
  }

  @media (max-width: 768px) {
    margin-bottom: 2rem;

    .swiper-pagination {
      bottom: 0rem;
    }
  }
`;

const SliderContent = styled.div`
  width: 100%;

  .media_slider {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .media_content {
    width: 50%;

    .title {
      margin-bottom: 2rem;

      span {
        color: #94c325;
      }
    }
  }

  .media_slider-image {
    width: 50%;

    display: flex;
    justify-content: flex-end;
    width: 700px;
    height: 400px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

export default BusinessSliderContent;
