import { FC } from "react";
import { IParcelsInformation } from "../../../types/assets.types";
import { Block } from "../../atoms/UI/Block";
import { PrimaryButtonStyled } from "../../atoms/UI/Buttons";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

interface IProps {
  parcels: IParcelsInformation;
  setPdfInvoice: any;
}

const AllBlock: FC<IProps> = ({ parcels, setPdfInvoice }) => {
  if (parcels.History?.length === 0) {
    return (
      <div className="title-block" style={{ textAlign: "center" }}>
        Пусто
      </div>
    );
  }

  const handle = (i: any) => {
    setPdfInvoice(i)
  };

  return (
    <>
      {parcels?.History?.map((item, index) => (
        <>
          <Block
            style={{
              backgroundColor: item.payment ? "#94c325" : "#DF5146",
              marginTop: "2rem",
              color: "white",
              cursor: "pointer",
            }}
            key={index + 1}
          >
            <div className="title-block invoice-title" onClick={() => handle(item.invoice)}>
              Номер накладной: <InvoiceSpan>{item.invoice}</InvoiceSpan>
            </div>
            <div className="subtitle-block">Дата: {item.date}</div>
            <div className="subtitle-block">Статус: {item.status}</div>

            {!item.payment && (
              <Link to={"/payment"}>
                <PrimaryButtonStyled
                  background="#94c325"
                  style={{ marginTop: "1.2rem" }}
                >
                  Оплатить
                </PrimaryButtonStyled>
              </Link>
            )}
          </Block>
        </>
      ))}
    </>
  );
};

const InvoiceSpan = styled.span`
  text-decoration: underline;
  color: white !important; 
`

export default AllBlock;
