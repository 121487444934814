import styled from "@emotion/styled";
import MainLayouts from "../layouts/MainLayouts";
import MapCard from "../components/molecules/MapBlock";
import { useState } from "react";

const Map = () => {
  
  const [cityName, setCityName] = useState<string>()

  return (
    <MainLayoutStyled title="Карта" className="section--map">
      <div className="title">
        Пункты Альфа в городе{" "}
        <span className="title title--green">{cityName && cityName}</span>
      </div>

      <MapBlock className="map">
        <MapCard setCityName={setCityName}/>
      </MapBlock>
    </MainLayoutStyled>
  );
};

const MainLayoutStyled = styled(MainLayouts)`
    &.section--map {
        @media (max-width: 768px) {
            padding-bottom: 0;
            .container {
                padding-inline: 0;
            }

            .map_filter {
                padding-inline: 2rem;
            }

            .title {
                padding-left: 2rem;
            }
        }
    }
`

const MapBlock = styled.div`
  .map_filter-search {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 30px;
    }

    input {
      padding-left: 6.2rem;

      width: 100%;
      max-width: 460px;
    }
  }

  .map_fllter-check {
    margin-top: 2rem;

    display: flex;
    align-items: center;
    gap: 3rem;
  }

  @media (max-width: 768px) {
    .map_fllter-check {
        flex-wrap: wrap;
        row-gap: 1.2rem;
    }
  }
`;

export default Map;
